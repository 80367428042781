const actions = {
  SET_STATE: 'report-outlet-profitability/SET_STATE',
  FILTER: 'report-outlet-profitability/FILTER',
  CANCEL: 'report-outlet-profitability/CANCEL',
  DOWNLOAD: 'report-outlet-profitability/DOWNLOAD',
  LIST_OUTLET: 'report-outlet-profitability/LIST_OUTLET',
  CANCEL_LIST_OUTLET: 'report-outlet-profitability/CANCEL_LIST_OUTLET',
}

export default actions

