import { all } from 'redux-saga/effects'
import user from './user/sagas'
import userDistributor from './user-distributor/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'

// USER ACCESS
import area from './user-access/area/sagas'
import areaInactive from './user-access/area-inactive/sagas'
import region from './user-access/region/sagas'
import regionInactive from './user-access/region-inactive/sagas'
import brandGroup from './user-access/brand-group/sagas'
import brandGroupInactive from './user-access/brand-group-inactive/sagas'
import salesType from './user-access/outlet-sales-type/sagas'
import spgType from './user-access/outlet-spg-type/sagas'
import spgPosition from './user-access/outlet-spg-position/sagas'
import outletGroup from './user-access/outlet-group/sagas'
import outletGroupTemp from './user-access/outlet-group-temp/sagas'
import outletChannelInactive from './user-access/outlet-channel-inactive/sagas'
import salesTypeInactive from './user-access/outlet-sales-type-inactive/sagas'
import spgTypeInactive from './user-access/outlet-spg-type-inactive/sagas'
import spgPositionInactive from './user-access/outlet-spg-position-inactive/sagas'
import outletGroupInactive from './user-access/outlet-group-inactive/sagas'
import outletChannel from './user-access/outlet-channel/sagas'
import categorySub from './user-access/category-sub/sagas'
import categorySubInactive from './user-access/category-sub-inactive/sagas'
import categoryCeo from './user-access/category-ceo/sagas'
import categoryCeoInactive from './user-access/category-ceo-inactive/sagas'
import categoryAccenture from './user-access/category-accenture/sagas'
import categoryAccentureInactive from './user-access/category-accenture-inactive/sagas'
import categoryAcc from './user-access/category-acc/sagas'
import categoryAccInactive from './user-access/category-acc-inactive/sagas'
import categoryStock from './user-access/category-stock/sagas'
import categoryStockInactive from './user-access/category-stock-inactive/sagas'
import categorySubStock from './user-access/category-sub-stock/sagas'
import categorySubStockInactive from './user-access/category-sub-stock-inactive/sagas'
import category from './user-access/category/sagas'
import categoryInactive from './user-access/category-inactive/sagas'
import variant from './user-access/variant/sagas'
import variantInactive from './user-access/variant-inactive/sagas'
import rangeVariant from './user-access/range-variant/sagas'
import rangeVariantInactive from './user-access/range-variant-inactive/sagas'
import rangeMarketing from './user-access/range-marketing/sagas'
import rangeMarketingInactive from './user-access/range-marketing-inactive/sagas'
import dashboard from './user-access/dashboard/sagas'
import areaSub from './user-access/area-sub/sagas'
import areaSubInactive from './user-access/area-sub-inactive/sagas'
import brand from './user-access/brand/sagas'
import brandInactive from './user-access/brand-inactive/sagas'
import brandCategory from './user-access/brand-category/sagas'
import brandBusiness from './user-access/brand-business/sagas'
import categoryVitalis from './user-access/category-vitalis/sagas'
import productRange from './user-access/product-range/sagas'
import productRangeInactive from './user-access/product-range-inactive/sagas'
import productListing from './user-access/product-listing/sagas'
import ar from './user-access/ar/sagas'
import outletChannelSub from './user-access/outlet-channel-sub/sagas'
import outletChannelSubInactive from './user-access/outlet-channel-sub-inactive/sagas'
import outletActive from './user-access/outlet-active/sagas'
import outletInactive from './user-access/outlet-inactive/sagas'
import outletLatLngImage from './user-access/outlet-latlng-image/sagas'
import outletLatLngImageInactive from './user-access/outlet-latlng-image-inactive/sagas'
import outletLatLngImageApproval from './user-access/outlet-latlng-image-approval/sagas'
import outletWithSpg from './user-access/outlet-with-spg-active/sagas'
import outletWithSpgInactive from './user-access/outlet-with-spg-inactive/sagas'
import outletWithSpgApproval from './user-access/outlet-with-spg-approval/sagas'
import outletWithSpgTemp from './user-access/outlet-with-spg-temp/sagas'
import outletPriority from './user-access/outlet-priority/sagas'
import outletPriorityInactive from './user-access/outlet-priority-inactive/sagas'
import outletTl from './user-access/outlet-tl/sagas'
import outletTlDeviasi from './user-access/outlet-tl-deviasi/sagas'
import outletTlInactive from './user-access/outlet-tl-inactive/sagas'
import outletTlApproval from './user-access/outlet-tl-approval/sagas'
import outletTlSpv from './user-access/outlet-tl-spv/sagas'
import outletTlSpvDeviasi from './user-access/outlet-tl-spv-deviasi/sagas'
import outletTlSpvInactive from './user-access/outlet-tl-spv-inactive/sagas'
import outletTlSpvApproval from './user-access/outlet-tl-spv-approval/sagas'
import outletSalesSpv from './user-access/outlet-sales-spv/sagas'
import outletSalesSpvDeviasi from './user-access/outlet-sales-spv-deviasi/sagas'
import outletSalesSpvInactive from './user-access/outlet-sales-spv-inactive/sagas'
import outletSalesSpvApproval from './user-access/outlet-sales-spv-approval/sagas'
import outletMd from './user-access/outlet-md/sagas'
import outletMdDeviasi from './user-access/outlet-md-deviasi/sagas'
import outletMdInactive from './user-access/outlet-md-inactive/sagas'
import outletMdApproval from './user-access/outlet-md-approval/sagas'
import outletSpg from './user-access/outlet-spg/sagas'
import outletSpgInactive from './user-access/outlet-spg-inactive/sagas'
import outletSpgDeviasi from './user-access/outlet-spg-deviasi/sagas'
import outletSpgApproval from './user-access/outlet-spg-approval/sagas'
import outletSpgTemp from './user-access/outlet-spg-temp/sagas'
import outletDistDistributor from './user-access/outlet-dist-distributor/sagas'
import outletDist from './user-access/outlet-dist/sagas'
import outletDistDeviasi from './user-access/outlet-dist-deviasi/sagas'
import outletDistTemp from './user-access/outlet-dist-temp/sagas'
import outletDistTempNew from './user-access/outlet-dist-temp-new/sagas'
import outletDistBlock from './user-access/outlet-dist-block/sagas'
import outletTemp from './user-access/outlet-temp/sagas'
import outletApproval from './user-access/outlet-approval/sagas'
import outletAccess from './user-access/outlet-access/sagas'
import outletDistAccess from './user-access/outlet-dist-access/sagas'
import outletDistApproval from './user-access/outlet-dist-approval/sagas'
import outletDistBlockApproval from './user-access/outlet-dist-approval-block/sagas'
import outletDistApprovalNew from './user-access/outlet-dist-approval-new/sagas'
import product from './user-access/product/sagas'
import settingConverter from './user-access/setting-converter/sagas'
import settingPoSummarySalesman from './user-access/setting-po-summary-salesman/sagas'
import closingMonth from './user-access/closing-month/sagas'
import closingOutletMonth from './user-access/closing-outlet-month/sagas'

import userList from './user-access/user-list/sagas'
import userListInactive from './user-access/user-list-inactive/sagas'
import distributorPrimary from './user-access/distributor-primary/sagas'
import distributorPrimaryDeviasi from './user-access/distributor-primary-deviasi/sagas'
import distributorPrimaryBlock from './user-access/distributor-primary-block/sagas'
import distributorGroup from './user-access/distributor-group/sagas'
import distributorEpm from './user-access/distributor-epm/sagas'
import distributorEpmDeviasi from './user-access/distributor-epm-deviasi/sagas'
import distributorEpmBlock from './user-access/distributor-epm-block/sagas'
import distributor from './user-access/distributor/sagas'
import distributorFile from './distributor-file/sagas'
import distributorApproval from './user-access/distributor-approval/sagas'
import approvalBalanceSummary from './user-access/approval-balance-summary/sagas'
import distributorChild from './user-access/distributor-child/sagas'
import distributorInactive from './user-access/distributor-inactive/sagas'
import distributorChildInactive from './user-access/distributor-child-inactive/sagas'
import position from './user-access/position/sagas'
import productDistDistributor from './user-access/product-dist-distributor/sagas'
import productDist from './user-access/product-dist/sagas'
import productDistGroup from './user-access/product-dist-group/sagas'
import productDistAccess from './user-access/product-dist-access/sagas'
import productDistBlock from './user-access/product-dist-block/sagas'
import productDistDeviasi from './user-access/product-dist-deviasi/sagas'
import productInactive from './user-access/product-inactive/sagas'
import productActive from './user-access/product-active/sagas'
import productTemp from './user-access/product-temp/sagas'
import productApproval from './user-access/product-approval/sagas'
import productDistTemp from './user-access/product-dist-temp/sagas'
import productDistApproval from './user-access/product-dist-approval/sagas'
import productDistGroupApproval from './user-access/product-dist-group-approval/sagas'
import productDistGroupTemp from './user-access/product-dist-group-temp/sagas'
import productDistBlockApproval from './user-access/product-dist-block-approval/sagas'
import productPrimary from './user-access/product-primary/sagas'
import productPrimaryBlock from './user-access/product-primary-block/sagas'
import productPrimaryDeviasi from './user-access/product-primary-deviasi/sagas'
import productPrimaryInactive from './user-access/product-primary-inactive/sagas'
import productTop from './user-access/product-top/sagas'
import productTopInactive from './user-access/product-top-inactive/sagas'
import productTopTemp from './user-access/product-top-temp/sagas'
import productTopMonthly from './user-access/product-top-monthly/sagas'
import productTopMonthlyInactive from './user-access/product-top-monthly-inactive/sagas'
import productMssMonthlyTemp from './user-access/product-mss-monthly-temp/sagas'
import productMssMonthly from './user-access/product-mss-monthly/sagas'
import productMssMonthlyInactive from './user-access/product-mss-monthly-inactive/sagas'
import productPrice from './user-access/product-price/sagas'
import productBundle from './user-access/product-bundle/sagas'
import productBundleInactive from './user-access/product-bundle-inactive/sagas'
import productBundleTemp from './user-access/product-bundle-temp/sagas'
import version from './user-access/version/sagas'
import month from './user-access/month/sagas'
import period from './user-access/period/sagas'
import year from './user-access/year/sagas'
import versionInactive from './user-access/version-inactive/sagas'
import monthInactive from './user-access/month-inactive/sagas'
import periodInactive from './user-access/period-inactive/sagas'
import yearInactive from './user-access/year-inactive/sagas'
import targetPrimarySales from './user-access/target-primary-sales/sagas'
import targetPrimarySalesApproval from './user-access/target-primary-sales-approval/sagas'
import targetPrimarySalesTemp from './user-access/target-primary-sales-temp/sagas'
import targetPrimaryBrand from './user-access/target-primary-brand/sagas'
import targetPrimaryBrandTemp from './user-access/target-primary-brand-temp/sagas'
import targetSecondaryDist from './user-access/target-secondary-dist/sagas'
import targetSecondaryDistApproval from './user-access/target-secondary-dist-approval/sagas'
import targetSecondaryDistTemp from './user-access/target-secondary-dist-temp/sagas'
import targetSecondaryBrand from './user-access/target-secondary-brand/sagas'
import targetSecondaryBrandApproval from './user-access/target-secondary-brand-approval/sagas'
import targetSecondaryBrandTemp from './user-access/target-secondary-brand-temp/sagas'
import forecastTargetSecondarySales from './user-access/forecast-target-secondary-sales/sagas'
import targetSecondarySales from './user-access/target-secondary-sales/sagas'
import targetSecondarySalesApproval from './user-access/target-secondary-sales-approval/sagas'
import targetSecondarySalesTemp from './user-access/target-secondary-sales-temp/sagas'
import targetSecondarySalesCb from './user-access/target-secondary-sales-cb/sagas'
import targetSecondarySalesCbApproval from './user-access/target-secondary-sales-cb-approval/sagas'
import targetSecondarySalesCbTemp from './user-access/target-secondary-sales-cb-temp/sagas'
import targetSecondarySalesRental from './user-access/target-secondary-sales-rental/sagas'
import targetSecondarySalesRentalApproval from './user-access/target-secondary-sales-rental-approval/sagas'
import targetSecondarySalesRentalTemp from './user-access/target-secondary-sales-rental-temp/sagas'
import targetSecondarySalesOutlet from './user-access/target-secondary-sales-outlet/sagas'
import targetSecondarySalesOutletApproval from './user-access/target-secondary-sales-outlet-approval/sagas'
import targetSecondarySalesOutletTemp from './user-access/target-secondary-sales-outlet-temp/sagas'
import targetSecondarySalesSpg from './user-access/target-secondary-sales-spg/sagas'
import targetSecondarySalesSpgApproval from './user-access/target-secondary-sales-spg-approval/sagas'
import targetSecondarySalesSpgTemp from './user-access/target-secondary-sales-spg-temp/sagas'
import targetSecondarySalesLcs from './user-access/target-secondary-sales-lcs/sagas'
import targetSecondarySalesLcsApproval from './user-access/target-secondary-sales-lcs-approval/sagas'
import targetSecondarySalesLcsTemp from './user-access/target-secondary-sales-lcs-temp/sagas'
import targetSecondaryChannelSub from './user-access/target-secondary-channel-sub/sagas'
import targetSecondaryChannelSubApproval from './user-access/target-secondary-channel-sub-approval/sagas'
import targetSecondaryChannelSubTemp from './user-access/target-secondary-channel-sub-temp/sagas'
import certificateGtOnline from './user-access/certificate-gt-online/sagas'
import certificateGtOnlineApproval from './user-access/certificate-gt-online-approval/sagas'
import loaGtOnline from './user-access/loa-gt-online/sagas'
import loaGtOnlineApproval from './user-access/loa-gt-online-approval/sagas'
import setting from './user-access/setting/sagas'
import notification from './user-access/notification/sagas'
import permission from './user-access/permission/sagas'
import role from './user-access/role/sagas'
import schedule from './user-access/schedule/sagas'
import scheduleAccounting from './user-access/schedule-accounting/sagas'
import transDistUpload from './user-access/trans-dist-upload/sagas'
import transDistUploadStock from './user-access/trans-dist-upload-stock/sagas'
import transDistUploadDraft from './user-access/trans-dist-upload-draft/sagas'
import transDistUploadDraftDistributor from './user-access/trans-dist-upload-draft-distributor/sagas'
import transDistUploadStockDraft from './user-access/trans-dist-upload-stock-draft/sagas'
import transDistUploadStockDraftDistributor from './user-access/trans-dist-upload-stock-draft-distributor/sagas'
import transDistUploadDaily from './user-access/trans-dist-upload-daily/sagas'
import transDistUploadWeekly from './user-access/trans-dist-upload-weekly/sagas'
import transDistUploadStockDaily from './user-access/trans-dist-upload-stock-daily/sagas'
import transDistUploadStockWeekly from './user-access/trans-dist-upload-stock-weekly/sagas'
import transFinalReport from './user-access/trans-final-report/sagas'
import transFinalReportDistributor from './user-access/trans-final-report-distributor/sagas'
import transFinalReportStock from './user-access/trans-final-report-stock/sagas'
import transFinalReportStockDistributor from './user-access/trans-final-report-stock-distributor/sagas'
import transDistStatusCube from './user-access/trans-dist-status-cube/sagas'
import transDistStatusCubeMmaMax from './user-access/trans-dist-status-cube-mma-mmax/sagas'
import transDistStatusDiscount from './user-access/trans-dist-status-discount/sagas'
import convertUnion from './user-access/convert-union/sagas'
import convertUnionStock from './user-access/convert-union-stock/sagas'
import transDistStatusCubeEwom from './user-access/trans-dist-status-cube-ewom/sagas'
import uncombine from './user-access/uncombine/sagas'
import combine from './user-access/combine/sagas'
import uploadDetail from './user-access/data-upload-detail/sagas'
import uploadDetailStock from './user-access/data-upload-detail-stock/sagas'
import listSales from './user-access/list-sales/sagas'

import reportMss from './user-access/report-mss/sagas'
import reportPrimarySales from './user-access/report-primary-sales/sagas'
import reportPrimarySalesByBrand from './user-access/report-primary-sales-by-brand/sagas'
import reportWeeklyPrimarySales from './user-access/report-weekly-primary-sales/sagas'
import reportDoByDaily from './user-access/report-do-by-daily/sagas'
import reportDo from './user-access/report-do/sagas'
import reportSoVsDo from './user-access/report-so-vs-do/sagas'
import reportAchByBrand from './user-access/report-ach-by-brand/sagas'
import reportTargetAchPeriodByBrand from './user-access/report-target-ach-period-by-brand/sagas'
import reportOpscCosmoByOutletAndRange from './user-access/report-opsc-cosmo-by-outlet-and-range/sagas'
import reportTopParetto from './user-access/report-top-paretto/sagas'
import reportNoTransThreeMma from './user-access/report-no-trans-three-mma/sagas'
import reportNoTransThreeMmaOutletOnly from './user-access/report-no-trans-three-mma-outlet-only/sagas'
import reportNoTransFourMmaOutlet from './user-access/report-no-trans-four-mma-outlet/sagas'
import reportNoTransTwoMmaOutlet from './user-access/report-no-trans-two-mma-outlet/sagas'
import reportNooTracking from './user-access/report-noo-tracking/sagas'
import reportProductivityByMaster from './user-access/report-productivity-by-master/sagas'
import reportOutletProfitability from './user-access/report-outlet-profitability/sagas'
import reportTimeSeries from './user-access/report-time-series/sagas'
import reportTimeSeriesPromotor from './user-access/report-time-series-promotor/sagas'
import reportHistoryOutlet from './user-access/report-history-outlet/sagas'
import reportProductTop100 from './user-access/report-product-top-100/sagas'
import reportProductTop100And50 from './user-access/report-product-top-100-and-50/sagas'
import reportIncentiveAss from './user-access/report-incentive-ass/sagas'
import reportIncentiveAsm from './user-access/report-incentive-asm/sagas'
import reportIncentiveRsm from './user-access/report-incentive-rsm/sagas'
import reportIncentiveRps from './user-access/report-incentive-rps/sagas'
import reportIncentiveQuarterAss from './user-access/report-incentive-quarter-ass/sagas'
import reportIncentiveQuarterAsm from './user-access/report-incentive-quarter-asm/sagas'
import reportIncentiveQuarterRsm from './user-access/report-incentive-quarter-rsm/sagas'
import reportIncentiveQuarterRps from './user-access/report-incentive-quarter-rps/sagas'
import reportIncentiveTlSpv from './user-access/report-incentive-tl-spv/sagas'
import reportIncentiveTl from './user-access/report-incentive-tl/sagas'
import reportIncentiveSpg from './user-access/report-incentive-spg/sagas'
import reportIncentiveSpv from './user-access/report-incentive-spv/sagas'
import reportIncentiveGroupPromotorManager from './user-access/report-incentive-group-promotor-manager/sagas'
import reportIncentiveHomt from './user-access/report-incentive-homt/sagas'
import reportIncentiveNkam from './user-access/report-incentive-nkam/sagas'
import reportIncentiveKam from './user-access/report-incentive-kam/sagas'
import reportIncentiveSos from './user-access/report-incentive-sos/sagas'
import reportIncentiveSom from './user-access/report-incentive-som/sagas'
import reportIncentiveSoe from './user-access/report-incentive-soe/sagas'
import reportIncentiveQuarterSom from './user-access/report-incentive-quarter-som/sagas'
import reportIncentiveQuarterSoe from './user-access/report-incentive-quarter-soe/sagas'
import reportIncentiveSalesman from './user-access/report-incentive-salesman/sagas'
import reportIncentiveDistributor from './user-access/report-incentive-distributor/sagas'
import reportProductivitySfaVsStar from './user-access/report-productivity-sfa-vs-star/sagas'
import reportDailyBrandSecSalesSummary from './user-access/report-daily-brand-sec-sales-summary/sagas'
import reportSecSalesAndProductivity from './user-access/report-sec-sales-and-productivity/sagas'
import reportKaSecSalesAndProductivity from './user-access/report-ka-sec-sales-and-productivity/sagas'
import reportDoByDistributor from './user-access/report-do-by-distributor/sagas'
import reportSecSalesByBrand from './user-access/report-sec-sales-by-brand/sagas'
import reportAchByTypeChannel from './user-access/report-ach-by-type-channel/sagas'
import reportAoByTypeChannel from './user-access/report-ao-by-type-channel/sagas'
import reportAchByBrandRangeChannelSub from './user-access/report-ach-by-brand-range-channel-sub/sagas'
import reportAchBySalesman from './user-access/report-ach-by-salesman/sagas'
import reportBrandBySalesman from './user-access/report-brand-by-salesman/sagas'
import reportEcBySalesman from './user-access/report-ec-by-salesman/sagas'
import reportAoBySalesman from './user-access/report-ao-by-salesman/sagas'
import reportTypeChannelBySalesman from './user-access/report-type-channel-by-salesman/sagas'
import reportBusinessReview from './user-access/report-business-review/sagas'
import reportMr from './user-access/report-mr/sagas'
import reportBalanceSummary from './user-access/report-balance-summary/sagas'
import reportSalesSecondaryMtd from './user-access/report-sales-secondary-mtd/sagas'
import reportSalesSecondaryMtdByBrand from './user-access/report-sales-secondary-mtd-by-brand/sagas'
import targetIncentiveMss from './user-access/target-incentive-mss/sagas'
import targetTlsdLopSales from './user-access/target-tlsd-lop-sales/sagas'
import targetRoSales from './user-access/target-ro-sales/sagas'
import targetNpd from './user-access/target-npd/sagas'
import targetDayOfWork from './user-access/target-day-of-work/sagas'
import mensaAreaSub from './user-access/mensa-area-sub/sagas'
import epmAreaSub from './user-access/epm-area-sub/sagas'
import shippingPoint from './user-access/shipping-point/sagas'
import shippingPointInactive from './user-access/shipping-point-inactive/sagas'
import business from './user-access/business/sagas'
import claimPromoEpm from './user-access/claim-promo-epm/sagas'
import dailySend from './user-access/daily-send/sagas'
import detailDailySend from './user-access/detail-daily-send/sagas'
import stock from './user-access/stock/sagas'
import reportEwom from './user-access/report-ewom/sagas'
import reportMdJagoJualan from './user-access/report-md-jago-jualan/sagas'
import reportRom from './user-access/report-rom/sagas'
import reportRecapSalesmanEwom from './user-access/report-recap-salesman-ewom/sagas'
import reportRecapMdSpgEwom from './user-access/report-recap-md-spg-ewom/sagas'
import reportBasedEstimate from './user-access/report-based-estimate/sagas'
import reportBeAss from './user-access/report-be-ass/sagas'
import outletSalesPermission from './user-access/outlet-sales-permission/sagas'
import outletSalesActive from './user-access/outlet-sales-active/sagas'
import outletSalesInactive from './user-access/outlet-sales-inactive/sagas'
import outletSalesDeviasi from './user-access/outlet-sales-deviasi/sagas'
import outletSalesTemp from './user-access/outlet-sales-temp/sagas'
import outletSalesApproval from './user-access/outlet-sales-approval/sagas'
import transDistUploadDistributor from './user-access/trans-dist-upload-distributor/sagas'
import transDistUploadStockDistributor from './user-access/trans-dist-upload-stock-distributor/sagas'

// report sfa
import reportCall from './user-access/report-sfa/report-call/sagas'
import reportAbsen from './user-access/report-sfa/report-absen/sagas'
import reportAbsenPromotor from './user-access/report-sfa/report-absen-promotor/sagas'
import reportPosmDetail from './user-access/report-sfa/report-posm-detail/sagas'
import reportPosmSummary from './user-access/report-sfa/report-posm-summary/sagas'
import reportAccurationPjp from './user-access/report-sfa/report-accuration-pjp/sagas'
import reportCompetitor from './user-access/report-sfa/report-competitor/sagas'
import reportActitivyDailyMd from './user-access/report-sfa/report-activity-daily-md/sagas'
import reportActitivyMerchandising from './user-access/report-sfa/report-activity-merchandising/sagas'
import reportActitivyDailySfa from './user-access/report-sfa/report-activity-daily-sfa/sagas'
import reportAbsenSfa from './user-access/report-sfa/report-absen-sfa/sagas'
import reportProductivitySfa from './user-access/report-sfa/report-productivity-sfa/sagas'
import reportJourneSalesmanSfa from './user-access/report-sfa/report-journey-salesman-sfa/sagas'
import reportPoSummarySalesman from './user-access/report-sfa/report-po-summary-salesman/sagas'
import reportPoDetailSalesman from './user-access/report-sfa/report-po-detail-salesman/sagas'
import reportDisplaySalesman from './user-access/report-sfa/report-display-salesman/sagas'
import reportActitivyDailySpv from './user-access/report-sfa/report-activity-daily-spv/sagas'
import reportAbsenSpv from './user-access/report-sfa/report-absen-spv/sagas'
import reportIncentiveMd from './user-access/report-sfa/report-incentive-md/sagas'
import reportMail from './user-access/report-mail/sagas'

import dunningLetterLocal from './user-access/dunning-letter-local/sagas'
import dunningLetterLocalLog from './user-access/dunning-letter-local-log/sagas'
import detailDunningLetterLocalLog from './user-access/detail-dunning-letter-local-log/sagas'
import dunningLetterInterco from './user-access/dunning-letter-interco/sagas'
import dunningLetterIntercoLog from './user-access/dunning-letter-interco-log/sagas'
import detailDunningLetterIntercoLog from './user-access/detail-dunning-letter-interco-log/sagas'

// dashboard chart
import dashboardChart from './user-access/dashboard-chart/sagas'

// dashboard enseval chart
import dashboardEnsevalChart from './user-access/dashboard-enseval-chart/sagas'

// dashboard sfa
import dashboardSfaManagement from './user-access/dashboard-sfa/management/sagas'
import dashboardSfaMd from './user-access/dashboard-sfa/md/sagas'
import dashboardSfaSales from './user-access/dashboard-sfa/sales/sagas'
import dashboardSfaRo from './user-access/dashboard-sfa/ro/sagas'

// dashboard user
import dashboardUser from './user-access/dashboard-user/sagas'

// dashboard kindis
import dashboardKindis from './user-access/dashboard-kindis/sagas'

// master sfa
import sfaProductTop from './user-access/master-sfa/product/product-top/sagas'
import sfaProductTopInactive from './user-access/master-sfa/product/product-top-inactive/sagas'
import sfaProductTopTemp from './user-access/master-sfa/product/product-top-temp/sagas'
import banner from './user-access/master-sfa/banner/sagas'
import pkModule from './user-access/master-sfa/pk-module/sagas'
import tradePromo from './user-access/master-sfa/trade-promo/sagas'
import bannerInactive from './user-access/master-sfa/banner-inactive/sagas'
import pkModuleInactive from './user-access/master-sfa/pk-module-inactive/sagas'
import tradePromoInactive from './user-access/master-sfa/trade-promo-inactive/sagas'
import programRank from './user-access/master-sfa/program/rank/sagas'
import programRegular from './user-access/master-sfa/program/regular/sagas'
import programRental from './user-access/master-sfa/program/rental/sagas'

import consumerChannelProductBundle from './user-access/master-sfa/consumer/channel-product-bundle/sagas'
import consumerChannelProductBundleTemp from './user-access/master-sfa/consumer/channel-product-bundle-temp/sagas'
import consumerChannelProduct from './user-access/master-sfa/consumer/channel-product/sagas'
import consumerChannelProductTemp from './user-access/master-sfa/consumer/channel-product-temp/sagas'
import consumerChannelRange from './user-access/master-sfa/consumer/channel-range/sagas'
import consumerChannelRangeTemp from './user-access/master-sfa/consumer/channel-range-temp/sagas'
import consumerChannelBrand from './user-access/master-sfa/consumer/channel-brand/sagas'
import consumerChannelBrandTemp from './user-access/master-sfa/consumer/channel-brand-temp/sagas'
import consumerChannel from './user-access/master-sfa/consumer/channel/sagas'
import consumerChannelTemp from './user-access/master-sfa/consumer/channel-temp/sagas'

import consumerDistributorProductBundle from './user-access/master-sfa/consumer/distributor-product-bundle/sagas'
import consumerDistributorProductBundleTemp from './user-access/master-sfa/consumer/distributor-product-bundle-temp/sagas'
import consumerDistributorProduct from './user-access/master-sfa/consumer/distributor-product/sagas'
import consumerDistributorProductTemp from './user-access/master-sfa/consumer/distributor-product-temp/sagas'
import consumerDistributorRange from './user-access/master-sfa/consumer/distributor-range/sagas'
import consumerDistributorRangeTemp from './user-access/master-sfa/consumer/distributor-range-temp/sagas'
import consumerDistributorBrand from './user-access/master-sfa/consumer/distributor-brand/sagas'
import consumerDistributorBrandTemp from './user-access/master-sfa/consumer/distributor-brand-temp/sagas'
import consumerDistributor from './user-access/master-sfa/consumer/distributor/sagas'
import consumerDistributorTemp from './user-access/master-sfa/consumer/distributor-temp/sagas'

import consumerOutletProductBundle from './user-access/master-sfa/consumer/outlet-product-bundle/sagas'
import consumerOutletProductBundleTemp from './user-access/master-sfa/consumer/outlet-product-bundle-temp/sagas'
import consumerOutletProduct from './user-access/master-sfa/consumer/outlet-product/sagas'
import consumerOutletProductTemp from './user-access/master-sfa/consumer/outlet-product-temp/sagas'
import consumerOutletRange from './user-access/master-sfa/consumer/outlet-range/sagas'
import consumerOutletRangeTemp from './user-access/master-sfa/consumer/outlet-range-temp/sagas'
import consumerOutletBrand from './user-access/master-sfa/consumer/outlet-brand/sagas'
import consumerOutletBrandTemp from './user-access/master-sfa/consumer/outlet-brand-temp/sagas'
import consumerOutlet from './user-access/master-sfa/consumer/outlet/sagas'
import consumerOutletTemp from './user-access/master-sfa/consumer/outlet-temp/sagas'
import quiz from './user-access/master-sfa/quiz/sagas'
import quizInactive from './user-access/master-sfa/quiz-inactive/sagas'
import quizTemp from './user-access/master-sfa/quiz-temp/sagas'
//
export default function* rootSaga() {
  yield all([
    user(),
    userDistributor(),
    menu(),
    settings(),
    area(),
    areaInactive(),
    region(),
    regionInactive(),
    brandGroup(),
    brandGroupInactive(),
    salesType(),
    spgType(),
    spgPosition(),
    outletGroup(),
    outletGroupTemp(),
    outletAccess(),
    outletChannel(),
    salesTypeInactive(),
    spgTypeInactive(),
    spgPositionInactive(),
    outletGroupInactive(),
    outletChannelInactive(),
    categorySub(),
    categorySubInactive(),
    categoryAcc(),
    categoryAccInactive(),
    categoryCeo(),
    categoryCeoInactive(),
    categoryAccenture(),
    categoryAccentureInactive(),
    categoryStock(),
    categoryStockInactive(),
    categorySubStock(),
    categorySubStockInactive(),
    category(),
    categoryInactive(),
    dashboard(),
    variant(),
    variantInactive(),
    rangeVariant(),
    rangeVariantInactive(),
    rangeMarketing(),
    rangeMarketingInactive(),
    areaSub(),
    areaSubInactive(),
    brand(),
    brandInactive(),
    brandCategory(),
    brandBusiness(),
    categoryVitalis(),
    productRange(),
    productRangeInactive(),
    productListing(),
    ar(),
    outletChannelSub(),
    outletChannelSubInactive(),
    userList(),
    userListInactive(),
    distributorPrimary(),
    distributorPrimaryDeviasi(),
    distributorPrimaryBlock(),
    distributorEpm(),
    distributorEpmDeviasi(),
    distributorEpmBlock(),
    distributorGroup(),
    distributor(),
    distributorFile(),
    distributorApproval(),
    distributorInactive(),
    distributorChild(),
    distributorChildInactive(),
    approvalBalanceSummary(),
    position(),
    productDistDistributor(),
    productDist(),
    productDistGroup(),
    productDistAccess(),
    productDistBlock(),
    productDistDeviasi(),
    product(),
    productActive(),
    productInactive(),
    productTemp(),
    productDistTemp(),
    productDistGroupTemp(),
    productPrimaryDeviasi(),
    productPrimary(),
    productPrimaryBlock(),
    productPrimaryInactive(),
    productApproval(),
    productDistApproval(),
    productDistGroupApproval(),
    productDistBlockApproval(),
    productTop(),
    productTopInactive(),
    productTopTemp(),
    productTopMonthly(),
    productTopMonthlyInactive(),
    productMssMonthlyTemp(),
    productMssMonthly(),
    productMssMonthlyInactive(),
    productPrice(),
    productBundle(),
    productBundleInactive(),
    productBundleTemp(),
    outletInactive(),
    outletActive(),
    outletLatLngImage(),
    outletLatLngImageInactive(),
    outletLatLngImageApproval(),
    outletWithSpg(),
    outletWithSpgInactive(),
    outletWithSpgApproval(),
    outletWithSpgTemp(),
    outletPriority(),
    outletPriorityInactive(),
    outletTl(),
    outletTlDeviasi(),
    outletTlInactive(),
    outletTlApproval(),
    outletTlSpv(),
    outletTlSpvDeviasi(),
    outletTlSpvInactive(),
    outletTlSpvApproval(),
    outletSalesSpv(),
    outletSalesSpvDeviasi(),
    outletSalesSpvInactive(),
    outletSalesSpvApproval(),
    outletMd(),
    outletMdDeviasi(),
    outletMdInactive(),
    outletMdApproval(),
    outletSpg(),
    outletSpgInactive(),
    outletSpgDeviasi(),
    outletSpgApproval(),
    outletSpgTemp(),
    outletDistDistributor(),
    outletDist(),
    outletDistApproval(),
    outletDistBlockApproval(),
    outletDistApprovalNew(),
    outletDistTemp(),
    outletDistTempNew(),
    outletDistAccess(),
    outletDistDeviasi(),
    outletDistBlock(),
    outletTemp(),
    outletApproval(),
    version(),
    month(),
    year(),
    period(),
    versionInactive(),
    monthInactive(),
    yearInactive(),
    periodInactive(),
    closingMonth(),
    closingOutletMonth(),
    targetPrimarySales(),
    targetPrimarySalesApproval(),
    targetPrimarySalesTemp(),
    targetPrimaryBrand(),
    targetPrimaryBrandTemp(),
    targetSecondaryDist(),
    targetSecondaryDistApproval(),
    targetSecondaryDistTemp(),
    targetSecondaryBrand(),
    targetSecondaryBrandApproval(),
    targetSecondaryBrandTemp(),
    forecastTargetSecondarySales(),
    targetSecondarySales(),
    targetSecondarySalesApproval(),
    targetSecondarySalesTemp(),
    targetSecondarySalesCb(),
    targetSecondarySalesCbApproval(),
    targetSecondarySalesCbTemp(),
    targetSecondarySalesRental(),
    targetSecondarySalesRentalApproval(),
    targetSecondarySalesRentalTemp(),
    targetSecondarySalesOutlet(),
    targetSecondarySalesOutletApproval(),
    targetSecondarySalesOutletTemp(),
    targetSecondarySalesSpg(),
    targetSecondarySalesSpgApproval(),
    targetSecondarySalesSpgTemp(),
    targetSecondarySalesLcs(),
    targetSecondarySalesLcsApproval(),
    targetSecondarySalesLcsTemp(),
    targetSecondaryChannelSub(),
    targetSecondaryChannelSubApproval(),
    targetSecondaryChannelSubTemp(),
    certificateGtOnline(),
    certificateGtOnlineApproval(),
    loaGtOnline(),
    loaGtOnlineApproval(),
    setting(),
    notification(),
    schedule(),
    scheduleAccounting(),
    settingConverter(),
    settingPoSummarySalesman(),
    permission(),
    role(),
    transDistUpload(),
    transDistUploadStock(),
    transDistUploadDraft(),
    transDistUploadDraftDistributor(),
    transDistUploadStockDraft(),
    transDistUploadStockDraftDistributor(),
    uncombine(),
    combine(),
    uploadDetail(),
    uploadDetailStock(),
    transDistUploadDaily(),
    transDistUploadWeekly(),
    transDistUploadStockDaily(),
    transDistUploadStockWeekly(),
    transFinalReport(),
    transFinalReportDistributor(),
    transFinalReportStock(),
    transFinalReportStockDistributor(),
    transDistStatusCube(),
    transDistStatusCubeMmaMax(),
    transDistStatusDiscount(),
    convertUnion(),
    convertUnionStock(),
    transDistStatusCubeEwom(),
    reportMss(),
    reportPrimarySales(),
    reportPrimarySalesByBrand(),
    reportWeeklyPrimarySales(),
    reportDoByDaily(),
    reportDo(),
    reportSoVsDo(),
    reportAchByBrand(),
    reportTargetAchPeriodByBrand(),
    reportOpscCosmoByOutletAndRange(),
    reportAchByTypeChannel(),
    reportTopParetto(),
    reportNoTransThreeMma(),
    reportNoTransThreeMmaOutletOnly(),
    reportNoTransFourMmaOutlet(),
    reportNoTransTwoMmaOutlet(),
    reportNooTracking(),
    reportProductivityByMaster(),
    reportOutletProfitability(),
    reportTimeSeries(),
    reportTimeSeriesPromotor(),
    reportHistoryOutlet(),
    reportProductTop100(),
    reportProductTop100And50(),
    reportIncentiveAss(),
    reportIncentiveAsm(),
    reportIncentiveRsm(),
    reportIncentiveRps(),
    reportIncentiveQuarterAss(),
    reportIncentiveQuarterAsm(),
    reportIncentiveQuarterRsm(),
    reportIncentiveQuarterRps(),
    reportIncentiveTlSpv(),
    reportIncentiveTl(),
    reportIncentiveSpv(),
    reportIncentiveSpg(),
    reportIncentiveGroupPromotorManager(),
    reportIncentiveHomt(),
    reportIncentiveNkam(),
    reportIncentiveKam(),
    reportIncentiveSos(),
    reportIncentiveSom(),
    reportIncentiveSoe(),
    reportIncentiveQuarterSom(),
    reportIncentiveQuarterSoe(),
    reportIncentiveSalesman(),
    reportIncentiveDistributor(),
    reportProductivitySfaVsStar(),
    reportDailyBrandSecSalesSummary(),
    reportSecSalesAndProductivity(),
    reportKaSecSalesAndProductivity(),
    reportDoByDistributor(),
    reportSecSalesByBrand(),
    reportAoByTypeChannel(),
    reportAchByBrandRangeChannelSub(),
    reportAchBySalesman(),
    reportBrandBySalesman(),
    reportEcBySalesman(),
    reportAoBySalesman(),
    reportTypeChannelBySalesman(),
    reportBusinessReview(),
    reportMr(),
    reportBalanceSummary(),
    reportSalesSecondaryMtd(),
    reportSalesSecondaryMtdByBrand(),
    targetIncentiveMss(),
    targetTlsdLopSales(),
    targetDayOfWork(),
    targetRoSales(),
    targetNpd(),
    mensaAreaSub(),
    epmAreaSub(),
    shippingPoint(),
    shippingPointInactive(),
    business(),
    claimPromoEpm(),
    dailySend(),
    detailDailySend(),
    stock(),
    listSales(),
    reportEwom(),
    reportRom(),
    reportMdJagoJualan(),
    reportRecapSalesmanEwom(),
    reportRecapMdSpgEwom(),
    reportBasedEstimate(),
    reportBeAss(),
    outletSalesPermission(),
    outletSalesActive(),
    outletSalesInactive(),
    outletSalesDeviasi(),
    outletSalesTemp(),
    outletSalesApproval(),
    transDistUploadDistributor(),
    transDistUploadStockDistributor(),
    reportCall(),
    reportAbsenPromotor(),
    reportAbsen(),
    reportPosmDetail(),
    reportPosmSummary(),
    reportAccurationPjp(),
    reportCompetitor(),
    reportActitivyDailyMd(),
    reportActitivyMerchandising(),
    reportActitivyDailySfa(),
    reportAbsenSfa(),
    reportProductivitySfa(),
    reportJourneSalesmanSfa(),
    reportPoSummarySalesman(),
    reportPoDetailSalesman(),
    reportDisplaySalesman(),
    reportActitivyDailySpv(),
    reportAbsenSpv(),
    reportIncentiveMd(),
    reportMail(),
    dashboardEnsevalChart(),
    dashboardChart(),
    dashboardSfaManagement(),
    dashboardSfaMd(),
    dashboardSfaSales(),
    dashboardSfaRo(),
    dashboardUser(),
    dashboardKindis(),

    sfaProductTop(),
    sfaProductTopInactive(),
    sfaProductTopTemp(),
    banner(),
    pkModule(),
    tradePromo(),
    bannerInactive(),
    pkModuleInactive(),
    tradePromoInactive(),
    programRank(),
    programRegular(),
    programRental(),
    consumerChannelProductBundle(),
    consumerChannelProductBundleTemp(),
    consumerChannelProduct(),
    consumerChannelRange(),
    consumerChannelBrand(),
    consumerChannel(),
    consumerDistributorProductBundle(),
    consumerDistributorProductBundleTemp(),
    consumerDistributor(),
    consumerDistributorProduct(),
    consumerDistributorRange(),
    consumerDistributorBrand(),
    consumerOutletProductBundle(),
    consumerOutletProduct(),
    consumerOutletRange(),
    consumerOutletBrand(),
    consumerOutlet(),
    quiz(),
    quizInactive(),
    quizTemp(),
    consumerChannelProductTemp(),
    consumerChannelRangeTemp(),
    consumerChannelBrandTemp(),
    consumerChannelTemp(),
    consumerDistributorProductTemp(),
    consumerDistributorRangeTemp(),
    consumerDistributorTemp(),
    consumerDistributorBrandTemp(),
    consumerOutletProductBundleTemp(),
    consumerOutletProductTemp(),
    consumerOutletRangeTemp(),
    consumerOutletBrandTemp(),
    consumerOutletTemp(),

    dunningLetterLocal(),
    dunningLetterLocalLog(),
    detailDunningLetterLocalLog(),
    dunningLetterInterco(),
    dunningLetterIntercoLog(),
    detailDunningLetterIntercoLog()
  ])
}
