const actions = {
  SET_STATE: 'loa-gt-online/SET_STATE',
  SEARCH: 'loa-gt-online/SEARCH',
  CANCEL: 'loa-gt-online/CANCEL',
  CANCEL_LIST_OUTLET: 'loa-gt-online/CANCEL_LIST_OUTLET',
  CREATE: 'loa-gt-online/CREATE',
  EDIT: 'loa-gt-online/EDIT',
  STORE: 'loa-gt-online/STORE',
  UPDATE: 'loa-gt-online/UPDATE',
  DELETE: 'loa-gt-online/DELETE',
  FILTER: 'loa-gt-online/FILTER',
  UPLOAD_FILE: 'loa-gt-online/UPLOAD_FILE',
  LIST_OUTLET: 'loa-gt-online/LIST_OUTLET',
  DOWNLOAD: 'loa-gt-online/DOWNLOAD',
  REMINDER: 'loa-gt-online/REMINDER',
}

export default actions
