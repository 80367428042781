import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import style from './style.module.scss'

const mapStateToProps = ({ version }) => ({ version })

const Footer = ({ dispatch, version }) => {
  useEffect(() => {
    dispatch({
      type: 'version/APP_VERSION',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <a href="#" target="_blank" rel="noopener noreferrer" className={style.logo}>
          Star BI 3.0 - <p className='mb-0 d-inline font-weight-normal' style={{color:'#595c97'}}> {version.currentAppCodeVersion}</p>
        </a>
        <br />
        <p className="mb-0">{version.currentAppVersion}</p>
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(Footer))
