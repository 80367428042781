import { all, takeLatest, put, call, take, race, select } from 'redux-saga/effects'
import * as mService from 'services/webservice/user-access/outlet-sales-active'
// import { getErrorMessage } from 'services/helper/message'
import actions from './actions'

function* SEARCH({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { currentPagination: oldPagination } = yield select(state => state.outletSalesInactive)
  const { currentPagination, order } = payload || {}
  if (payload) {
    delete payload.currentPagination
  }
  const result = yield race({
    data: call(mService.search, payload),
    cancel: take(actions.CANCEL),
  })
  if (result.data) {
    const { response, error } = result.data
    const newPayload = {
      loading: false,
      error: null,
    }
    if (response) {
      newPayload.currentPagination = {
        ...oldPagination,
        ...currentPagination,
        total: response.data.recordsFiltered,
      }
      newPayload.order = { ...order }
      newPayload.dataOutletSalesInactive = response.data
    } else {
      newPayload.error = error
    }
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* GET_FORM(action) {
  const { payload } = action
  let newPayload = {
    loadingForm: true,
    dataOutletSalesInactiveCreate: null,
    dataOutletSalesInactiveEdit: null,
    messageFormSuccess: null,
  }
  if (payload.resetErrorForm) {
    newPayload.errorForm = null
  }
  yield put({
    type: actions.SET_STATE,
    payload: newPayload,
  })
  let result
  const isCreate = action.type === actions.CREATE
  if (isCreate) {
    result = yield race({
      data: call(mService.create),
      cancel: take(actions.CANCEL),
    })
  } else {
    result = yield race({
      data: call(mService.edit, payload.id),
      cancel: take(actions.CANCEL),
    })
  }
  if (result.data) {
    newPayload = {
      loadingForm: false,
    }
    const { response, error } = result.data
    if (isCreate) {
      newPayload.dataOutletSalesInactiveCreate = response ? response.data : null
    } else {
      newPayload.dataOutletSalesInactiveEdit = response ? response.data : null
    }
    newPayload.errorForm = error
    yield put({
      type: actions.SET_STATE,
      payload: newPayload,
    })
  }
}

function* STORE() {
  while (true) {
    const { payload } = yield take(actions.STORE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const result = yield race({
      data: call(mService.store, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* UPDATE() {
  while (true) {
    const { payload } = yield take(actions.UPDATE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingForm: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(mService.update, currentId, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingForm: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageFormSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorForm = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* DELETE() {
  while (true) {
    const { payload } = yield take(actions.DELETE)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingDelete: true,
        errorDelete: null,
        messageDeleteSuccess: null,
      },
    })
    const result = yield race({
      data: call(mService.deleteData, payload.id),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingDelete: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageDeleteSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.errorDelete = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* SWITCH_SALES_NAME() {
  while (true) {
    const { payload } = yield take(actions.SWITCH_SALES_NAME)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingSwitchSales: true,
      },
    })
    const result = yield race({
      data: call(mService.switchSalesName, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingSwitchSales: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageSwitchSalesSuccess = response.data
      }
      newPayload.messageSwitchSalesError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}

function* RESET_PASSWORD() {
  while (true) {
    const { payload } = yield take(actions.RESET_PASSWORD)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loadingResetPassword: true,
      },
    })
    const currentId = payload.id
    delete payload.id
    const result = yield race({
      data: call(mService.resetPassword, currentId, payload),
      cancel: take(actions.CANCEL),
    })
    if (result.data) {
      const newPayload = {
        loadingResetPassword: false,
      }
      const { response, error } = result.data
      if (response) {
        newPayload.messageResetPasswordSuccess = {
          title: `SUCCESS`,
          message: response.data.message,
        }
      }
      newPayload.messageResetPasswordError = error
      yield put({
        type: actions.SET_STATE,
        payload: newPayload,
      })
    }
  }
}


export default function* rootSaga() {
  yield all([
    takeLatest(actions.SEARCH, SEARCH),
    takeLatest([actions.CREATE, actions.EDIT], GET_FORM),
    STORE(),
    UPDATE(),
    DELETE(),
    SWITCH_SALES_NAME(),
    RESET_PASSWORD()
  ])
}
