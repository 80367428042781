const actions = {
  SET_STATE: 'outlet-sales-active/SET_STATE',
  SEARCH: 'outlet-sales-active/SEARCH',
  CANCEL: 'outlet-sales-active/CANCEL',
  CREATE: 'outlet-sales-active/CREATE',
  EDIT: 'outlet-sales-active/EDIT',
  STORE: 'outlet-sales-active/STORE',
  UPDATE: 'outlet-sales-active/UPDATE',
  SWITCH_SALES_NAME: 'outlet-sales-active/SWITCH_SALES_NAME',
  DELETE: 'outlet-sales-active/DELETE',
  FILTER: 'outlet-sales-active/FILTER',
  REMINDER: 'outlet-sales-active/REMINDER',
  RESET_PASSWORD: 'outlet-sales-active/RESET_PASSWORD',
}

export default actions
